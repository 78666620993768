import { FunctionComponent, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Gender, Genders } from 'models/Product';

import { withVariables } from 'utils/string';
import { getProvider } from 'services/Cookies';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutNavigationMobileDropDownMenu: FunctionComponent<Props> = ({ translations, children }) => {
    const [activeGender, setActiveGender] = useState<Genders>(null);
    const [openDropDown, setOpenDropdown] = useState<boolean>(false);
    const [openDropDownTreatments, setOpenDropDownTreatments] = useState<boolean>(false);

    const router = useRouter();

    useEffect(() => {
        const usedGender = getProvider().get(Gender.gender);
        setActiveGender(usedGender as Genders);
    }, []);

    useEffect(() => {
        if (router.pathname === Routes.PublicWemanZone) setActiveGender(Genders.Female);
        if (router.pathname === Routes.PublicMenZone) setActiveGender(Genders.Male);
    }, []);

    return (
        <StyledComponent className="public-layout-navigation-mobile-drop-down-menu">
            <div className="drop-down-wrapper">
                <div className="gender-zone-buttons">
                    {translations?.genderButtons?.buttonGroupProps?.buttons && (
                        translations?.genderButtons?.buttonGroupProps?.buttons.map((redirectButton) => (
                            <Link
                                key={redirectButton.children}
                                href={redirectButton.href}
                                prefetch={false}
                            >
                                <a
                                    className={classnames({
                                        'gender-button': true,
                                        'active': activeGender === redirectButton.key,
                                    })}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (redirectButton.key === Genders.Male) {
                                            getProvider().set(Gender.gender, Genders.Male);
                                            router.push(withVariables(Routes.PublicMenZone));
                                        }
                                        if (redirectButton.key === Genders.Female) {
                                            getProvider().set(Gender.gender, Genders.Female);
                                            router.push(withVariables(Routes.PublicWemanZone));
                                        }
                                    }}
                                >
                                    {redirectButton.children}
                                </a>
                            </Link>
                        ))
                    )}
                </div>
                <div className="pages">
                    {translations?.pages?.elements?.map((page) => (
                        <div
                            key={page.key}
                            className={classnames(
                                'pages-link',
                                {
                                    'highlighted': page.buttonProps?.highlighted,
                                },
                            )}
                        >
                            {page?.buttonProps?.href
                                ? (
                                    <Link
                                        href={page.buttonProps.href}
                                        prefetch={false}
                                    >
                                        <a
                                            className="pages-link-anchor"
                                            onClick={(e) => {
                                                if (page.subMenu && page.buttonProps.href === Routes.PublicAbout) {
                                                    e.preventDefault();
                                                    setOpenDropdown(!openDropDown);
                                                }
                                            }}
                                        >
                                            {page.buttonProps.children}
                                            {page.subMenu && page.buttonProps.href === Routes.PublicAbout && (
                                                <img
                                                    src="/images/home/arrow-down-nav.svg"
                                                    alt="icon"
                                                    width="10px"
                                                    height="10px"
                                                />
                                            )}
                                        </a>
                                    </Link>
                                )
                                : (
                                    <div
                                        className="pages-link-anchor"
                                        onClick={(e) => {
                                            if (page.subMenu && page?.key === 'otherTreatmets') {
                                                e.preventDefault();
                                                setOpenDropDownTreatments(!openDropDownTreatments);
                                            }
                                        }}
                                    >
                                        {page.buttonProps.children}
                                        {page.subMenu && !page?.buttonProps?.href && (
                                            <img
                                                src="/images/home/arrow-down-nav.svg"
                                                alt="icon"
                                                width="10px"
                                                height="10px"
                                            />
                                        )}
                                    </div>
                                )
                            }
                            {(page.subMenu && page.buttonProps.href === Routes.PublicAbout) && openDropDown && (
                                <div className="pages-link-about-links">
                                    {page.subMenu.sections.map((section) => (
                                        <Link
                                            key={section.key}
                                            href={section.href}
                                            prefetch={false}
                                        >
                                            <a className="pages-link-anchor">
                                                {section.title}
                                            </a>
                                        </Link>
                                    ))}
                                </div>
                            )}

                            {page.subMenu && !page?.buttonProps?.href && openDropDownTreatments && (
                                <div className="pages-link-about-links">
                                    {page.subMenu.sections.map((section) => (
                                        <Link
                                            key={section.key}
                                            href={section.href}
                                            prefetch={false}
                                        >
                                            <a className="pages-link-anchor">
                                                {section.title}
                                            </a>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="info-line">
                    {children}
                </div>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationMobileDropDownMenu;
