import { FunctionComponent, useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Gender, Genders } from 'models/Product';

import { withVariables } from 'utils/string';
import { getProvider } from 'services/Cookies';

import LocaleContext from 'components/context/Locale';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';

import PagesLinks from './PagesLinks';
import StyledComponent from './styles';
import { Props } from './types';

const TopBar = dynamic(() => import('components/modules/public/layout/Navigation/Desktop/TopBar'));

const PublicLayoutNavigationDesktop: FunctionComponent<Props> = ({ className, isScrolledTop, state, navigationElementVisibility }) => {
    const [addedToCart, setAddedToCart] = useState<boolean>(false);
    const [activeGender, setActiveGender] = useState<Genders>(null);

    const localeContext = useContext(LocaleContext);
    const translations = localeContext?.translations?.navigation;
    const router = useRouter();

    useEffect(() => {
        const usedGender = getProvider().get(Gender.gender);
        setActiveGender(usedGender as Genders);
    }, [router]);

    useEffect(() => {
        if (router.pathname === Routes.PublicWemanZone) setActiveGender(Genders.Female);
        if (router.pathname === Routes.PublicMenZone) setActiveGender(Genders.Male);
    }, []);

    return (
        <StyledComponent
            className={classnames(
                'public-layout-navigation-desktop',
                className,
            )}
        >
            <TopBar
                className={className}
                isScrolledTop={isScrolledTop}
                addedToCart={addedToCart}
                setAddedToCart={setAddedToCart}
                state={state}
                navigationElementVisibility={navigationElementVisibility}
            />
            <div className={classnames({
                'bottom': true,
                className,
                'd-none': !navigationElementVisibility?.pageLinks && !navigationElementVisibility?.zones,
            })}
            >
                <LayoutContainer size={Sizes.XXMedium}>
                    <div className="col col-left">
                        {navigationElementVisibility?.pageLinks && (
                            <PagesLinks pages={translations?.main?.elements} />
                        )}
                    </div>
                    <div className="col col-right">
                        {navigationElementVisibility?.zones && (
                            <div className="gender-zone-buttons">
                                {translations?.genderButtons?.buttonGroupProps?.buttons && (
                                    translations?.genderButtons?.buttonGroupProps?.buttons.map((redirectButton) => (
                                        <Link
                                            key={redirectButton.children}
                                            href={redirectButton.href}
                                            prefetch={false}
                                        >
                                            <a
                                                className={classnames({
                                                    'gender-button': true,
                                                    'active': activeGender === redirectButton.key,
                                                })}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (redirectButton.key === Genders.Male) {
                                                        getProvider().set(Gender.gender, Genders.Male);
                                                        router.push(withVariables(Routes.PublicMenZone));
                                                    }
                                                    if (redirectButton.key === Genders.Female) {
                                                        getProvider().set(Gender.gender, Genders.Female);
                                                        router.push(withVariables(Routes.PublicWemanZone));
                                                    }
                                                }}
                                            >
                                                {redirectButton.children}
                                            </a>
                                        </Link>
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationDesktop;
