import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    padding: 4em 0 2em 0;
    background: #484a52;

    .top {
        .layout-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: initial;

            .col {
                &.col-left {
                    width: 35%;
                }

                &.col-right {
                    width: 65%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            .contact-headline {
                text-align: left;
                font-size: 2.1em;
                font-weight: 800;
                color: white;
            }

            .contact-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 5em;

                .contact-box-col {
                    &.contact-box-col-icon {
                        margin-right: 1em;
                    }

                    &.contact-box-col-info {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .contact-box-label {
                            font-size: .9em;
                            color: white;
                            font-weight: 800;
                        }

                        .contact-box-value {
                            font-size: 1.5em;
                            color: white;
                            font-weight: 800;
                            text-decoration: none;
                        }

                        .contact-box-col-info-value {
                            font-size: .975em;
                            color: white;
                        }
                    }
                }

                &:last-of-type {
                    margin-right: 0%;
                }
            }
        }
    }

    .bottom {
        margin-top: 3em;

        .layout-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border-top: .01em solid #B5EBFF12;
            padding-top: 3em;

            .nav-headline {
                color: white;
                font-weight: 800;
                margin-bottom: .7em;
                font-size: .8em;
            }

            .col {
                &.col-logo {
                    min-width: 10em;
                }

                &.col-nav {
                    .layout-nav-section-simple {
                        gap: unset;

                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        > * {
                            margin-right: 0;
                            margin-bottom: 1em;
                        }
                    }

                    &.col-nav-side {
                        .layout-nav-section-simple {
                            .layout-nav-element {
                                .layout-button {
                                    .button {
                                        color: #D1D1D1;
                                    }
                                }
                            }
                        }
                    }
                }

                &.col-social {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    .social {
                        .nav-social-list {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .nav-social-element {
                                margin-right: .5em;

                                &:last-of-type {
                                    margin-right: 0%;
                                }
                            }
                        }
                    }

                    .partners {
                        margin-top: 3em;

                        .nav-partners-list {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .nav-partners-element {
                                margin-right: .5em;

                                &:last-of-type {
                                    margin-right: 0%;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    .locations {
        .locations-headline {
            font-size: 1.5em;
            font-weight: 800;
            color: white;
            text-align: center;
            margin-bottom: 2em;
        }

        .locations-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1em;

            .location {
                font-size: 0.8em;
                white-space: nowrap;
                text-decoration: none;
                color: #fff;
                font-weight: 500;

                span {
                    color: #a6a6a6;
                    font-weight: 400;
                }

                @media (pointer: fine) {
                    &:hover {
                        color: #BA9449;
                    }
                }
            }
        }

    }

    .additional-info {
        margin-top: 2em;

        .layout-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: .01em solid #B5EBFF12;
            border-top: .01em solid #B5EBFF12;
            padding: 2em 0;

            .link {
                width: 100%;
                max-width: 8em;
            }

            .icon {
                cursor: pointer;
            }


            .address-box {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .address-box-icon {
                    margin-right: 1em;
                }

                .address-box-content {
                    .address-box-label {
                        font-size: .9em;
                        color: white;
                        font-weight: 800;
                    }

                    .address-box-value {
                        font-size: .9em;
                        color: white;
                    }
                }
            }

            .socials {
                .socials-label {
                    font-size: .9em;
                    color: white;
                    font-weight: 800;
                }

                .socials-icons {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: .3em;

                    .icon {
                        margin-right: .5em;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    .logos {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em 0;

        .footer-logos {
            margin: 0 auto;
        }
    }

    .super-bottom {

        .layout-container {
            border-top: .01em solid #B5EBFF12;
            padding-top: 1em;
            display: flex;
            gap: 1em;
        }

        .copyright {
            font-size: .9em;
            font-weight: 300;
            color: #D1D1D1;
        }

        .cookie-preferences {
            font-size: .9em;
            font-weight: 300;
            color: #D1D1D1;
            cursor: pointer;

            @media (pointer: fine) {
                &:hover {
                    color: #BA9449;
                }
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .top {
            .layout-container {
                flex-direction: column;
                text-align: center;

                .col {
                    &.col-left {
                        width: 100%;

                        .contact-headline {
                            text-align: center;
                        }
                    }

                    &.col-right {
                        margin-top: 3em;
                        justify-content: center;
                        width: 100%;
                    }
                }
            }
        }

        .bottom {
            .layout-container {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .col {
                    width: 100%;
                    margin: 1em 0;
                    text-align: center;

                    .layout-nav-section-simple {
                        align-items: center;
                        justify-content: center;

                        .layout-nav-element {
                            width: 100%;;
                        }
                    }

                    &.col-social {
                        justify-content: center;

                        .social, .partners {
                            width: 100%;

                            .nav-social-list {
                                justify-content: center;
                            }

                            .nav-partners-list {
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }

        .super-bottom {
            text-align: center;
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .top {
            .layout-container {
                .col {
                    &.col-left {
                        .contact-headline {
                            font-size: 2em;
                        }
                    }

                    &.col-right {
                        flex-direction: column;

                        .contact-box {
                            margin-right: 0;
                            margin-bottom: 3em;

                            .contact-box-col {
                                align-items: center;

                                &.contact-box-col-info {
                                    .contact-box-label {
                                        font-size: 130%;
                                    }

                                    .contact-box-value {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            margin-top: 0;

            .layout-container {
                padding-top: 1em;

                .col {
                    &.col-nav {
                        .nav-headline {
                            font-size: 140%;
                        }

                        .layout-nav-section-simple {
                            > * {
                                font-size: 120%;
                            }
                        }
                    }

                    &.col-social {
                        .nav-headline {
                            font-size: 140%;
                        }
                    }
                }
            }
        }

        .locations {
            .locations-container {
                .location {
                    font-size: 110%;
                    color: white;
                }
            }

        }

        .additional-info {
            margin-top: 2em;

            .layout-container {
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .socials {
                    .socials-icons {
                        justify-content: space-around;
                        gap: 1em;
                        padding-top: 1em;

                        .icon {
                            margin-right: 0;
                        }
                    }
                }

                > * {
                    text-align: center;
                    padding: 1em 0;
                }
            }
        }

        .super-bottom {
            .layout-container {
                flex-direction: column-reverse;

                .copyright {
                    font-size: 120%;
                }
            }
        }
    }
`;
