import { Navigation } from 'locales/types/components';

import Component from './component';

export interface Props {
    translations: Navigation;
    isScrolledTop: boolean;
    isMobile?: boolean;
    mobileMenu?: boolean
}

Component.defaultProps = {};

export default Component;
