import styled from '@emotion/styled';

export default styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #262626;

    .navigation-wrapper {
        width: 100%;
        height: 100%;
        padding: 1em 2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .col-right {
            display: flex;
            align-items: center;
            gap: 1.2em;
            
            .profile {
                .profile-button {
                    min-width: 30px;
                    width: 30px;

                    .button-icon-after {
                        padding: 0.3em;
                        margin: 0;
                        border-radius: 0.6em;
                        border: 2px solid transparent;
                    }

                    .profile-link-logged-in {
                        .button {
                            .button-icon-after {
                                border-color: #ba9349;
                                padding: 0.4em;
                            }
                        }
                    }
                }
            }

            .cart {
                .cart-button {
                    position: relative;

                    .cart-counter {
                        position: absolute;
                        color: white;
                        background-color: #ba9449;
                        width: 22px;
                        height: 22px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 22px;
                        z-index: 2;
                        bottom: -12px;
                        right: -14px;
                    }
                }
            }

            .menu-button-container {
                width: 30px;
                height: 30px;
                margin-left: 0.8em;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                .menu-button {
                    position: relative;
                    height: 2px;
                    width: 24px;
                    background-color: white;
                    border-radius: 2px;

                    &:before,
                    &:after {
                        position: absolute;
                        content: '';
                        background-color: white;
                        height: inherit;
                        width: inherit;
                        border-radius: inherit;
                        transition: transform 200ms ease;
                    }

                    &:before {
                        top: -8px;
                    }

                    &:after {
                        bottom: -8px;
                    }

                    &-active {
                        background-color: transparent;

                        &:before {
                            top: 0;
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
`;
