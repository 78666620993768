import { FunctionComponent, useContext, useEffect } from 'react';
import getConfig from 'next/config';

import LocaleContext from 'components/context/Locale';

import { Props } from './types';

declare let initCookieConsent: any;

const LayoutCookiesPanel: FunctionComponent<Props> = () => {
    const localeContext = useContext(LocaleContext);
    const translations = localeContext?.translations?.navigation?.cookiePanel;
    const { publicRuntimeConfig } = getConfig();

    useEffect(() => {
        if(typeof initCookieConsent === 'undefined' || !initCookieConsent) return;
        const cookieconsent = initCookieConsent();

        cookieconsent.run({
            current_lang : publicRuntimeConfig.APP_LOCALE === 'pl' ? 'pl' : 'en',

            onAccept : () => {
                if(cookieconsent.allowedCategory('analytics')) {
                    // TODO: Set gtm consents
                    // grantGtmConsent();
                }
            },

            gui_options: {
                consent_modal : {
                    layout : 'cloud',               // box/cloud/bar
                    position : 'bottom center',     // bottom/top + left/right/center
                    transition: 'slide',             // zoom/slide
                },
                settings_modal : {
                    layout : 'box',                 // box/bar
                    // position : 'left',           // left/right
                    transition: 'slide',             // zoom/slide
                },
            },

            languages : {
                en : {
                    consent_modal : {
                        title :  '',
                        description : translations.description,
                        primary_btn: {
                            text: translations.buttons.accept,
                            role: 'accept_all',  // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text : translations.buttons.settings,
                            role : 'settings',   // 'settings' or 'accept_necessary'
                        },
                    },
                    settings_modal : {
                        title : translations.settingsModal.title,
                        save_settings_btn : translations.buttons.save,
                        accept_all_btn : translations.buttons.acceptAll,
                        blocks : [
                            {
                                title: '',
                                description : translations.settingsModal.description,
                            },
                            {
                                title : translations.settingsModal.necessary.title,
                                description: translations.settingsModal.necessary.subTitle,
                                toggle : {
                                    value : 'necessary',
                                    enabled : true,
                                    readonly: true,
                                },
                            }, {
                                title : translations.settingsModal.analytical.title,
                                description: translations.settingsModal.analytical.subTitle,
                                toggle : {
                                    value : 'analytics',
                                    enabled : false,
                                    readonly: false,
                                },
                            },
                            {
                                title : translations.settingsModal.marketing.title,
                                description: translations.settingsModal.marketing.subTitle,
                                toggle : {
                                    value : 'marketing',
                                    enabled : false,
                                    readonly: false,
                                },
                            },
                        ],
                    },
                },
            },
        });
    }, []);

    return null;
};

export default LayoutCookiesPanel;
