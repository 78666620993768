import { FunctionComponent, useEffect, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { CookieKeys } from 'types/cookies';
import { Routes } from 'types/routes';
import { Events } from 'types/tracking';

import { filterMarketingQuery } from 'utils/marketing';
import { getProvider } from 'services/Cookies';
import Tracking from 'services/Tracking';

import CookiesPanel from 'components/modules/public/layout/CookiesPanel';
import Footer from 'components/modules/public/layout/Footer';
import Navigation, { initialNavigationElements } from 'components/modules/public/layout/Navigation';
import { Variants } from 'components/modules/public/layout/Navigation/types';
import useScroll from 'hooks/useScroll';

import StyledComponent from './styles';
import { Props } from './types';

const FooterDelayedHydration = withHydrationOnDemand({ on: ['visible'] })(
    Footer
);

const WrapperPublic: FunctionComponent<Props> = ({
    children,
    topbarScrollEffect,
    forceVariant,
    navigationElementVisibility,
}) => {
    const router = useRouter();
    const scrollChange = useScroll();
    const [isScrollTop, setIsScrollTop]: [boolean, Function] = useState(true);
    const excludeFooterPages = [Routes.PublicCart];

    // Trigger Event ViewPage on every pathName change
    useEffect(() => {
        const originalLocation = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`;
        const pageTitle = document.title;

        Tracking.event(Events.CustomPageView, {
            page: {
                url: originalLocation,
                title: pageTitle,
            },
        });
    }, [router.asPath]);

    // Set last attribution cookie
    useEffect(() => {
        const attributionFirst = getProvider().get(CookieKeys.AttributionFirst);
        const marketingQuery = filterMarketingQuery(router.query);

        if (Object.keys(marketingQuery).length > 0) {
            if (!attributionFirst) getProvider().set(CookieKeys.AttributionFirst, JSON.stringify(marketingQuery));
            getProvider().set(CookieKeys.AttributionLast, JSON.stringify(marketingQuery));
        }
    }, [JSON.stringify(router.query)]);

    useEffect(() => {
        if (scrollChange > 0 && topbarScrollEffect) {
            return setIsScrollTop(false);
        }

        return setIsScrollTop(true);
    }, [scrollChange]);

    const scrollToTopWhenAvailable = topbarScrollEffect && isScrollTop;

    return (
        <StyledComponent className="wrapper-public">
            <Navigation
                className={classnames({
                    'is-scrolled-to-top': scrollToTopWhenAvailable && !forceVariant,
                })}
                navigationElementVisibility={{ ...initialNavigationElements, ...navigationElementVisibility }}
                isScrolledTop={forceVariant
                    ? false
                    : scrollToTopWhenAvailable}
                variant={forceVariant
                    ? forceVariant
                    : scrollToTopWhenAvailable ? Variants.Light : Variants.Dark
                }
            />
            <div className="wrapper-content">
                {children}
            </div>
            {!excludeFooterPages.includes(router.pathname as Routes) && (
                <FooterDelayedHydration />
            )}
            <CookiesPanel />
        </StyledComponent>
    );
};

export default WrapperPublic;
