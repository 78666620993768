import styled from '@emotion/styled';

export default styled.div`
    position: relative;
    width: 100%;
    min-width: 780px;
    min-height: 120px;
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;

    .sub-menu-section {
        min-width: 10em;
        width: 47%;

        &.sub-menu-section-image {
            display: flex;
            flex-direction: column;
            gap: 0.4em;
            cursor: pointer;
            text-decoration: none;

            .sub-menu-section-image {
                max-height: 180px;
                object-fit: cover;
                border-radius: 0.6em;
                transform: scale(1);
                transition: transform 200ms ease-in-out;

                & + .sub-menu-section-title {
                    margin-bottom: 1em;
                }
            }

            &:hover {
                .sub-menu-section-image {
                    transform: scale(1.04);
                }
            }
        }

        .sub-menu-section-title {
            color: #121212;
            font-weight: 800;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 0.4em;
        }

        .sub-menu-section-elements {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: baseline;

            .sub-menu-section-element {
                padding: 0.3em 0;
                font-size: 13px;
                cursor: pointer;
                position: relative;
                display: flex;
                text-decoration: none;
                color: #121212;
                opacity: 0.8;

                &:hover {
                    color: #BA9449;
                    opacity: 1;
                }
            }
        }
    }
`;
