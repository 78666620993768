import React, { useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { Props } from './index';
import StyledComponent from './styles';

const InfoLineNavSection: React.FC<Props> = ({
    translations,
    isScrolledTop,
    isMobile,
    mobileMenu,
}): React.ReactElement => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const daysConfigObj = {
        1: { from: 8, to: 20 },
        2: { from: 8, to: 20 },
        3: { from: 8, to: 20 },
        4: { from: 8, to: 20 },
        5: { from: 8, to: 20 },
        6: { from: 9, to: 17 },
        0: { from: 10, to: 18 },
    };

    const getInfoLineStatus = () => {
        const dayConfig = daysConfigObj[moment().day()];

        if (!dayConfig) return {
            translations: translations?.topRightInfoLineDisabled,
            infoLineEnabled: false,
        };

        if (moment().hour() > dayConfig.from && moment().hour() < dayConfig.to) return {
            translations: translations?.topRightInfoLineEnabled,
            infoLineEnabled: true,
        };

        return {
            translations: translations?.topRightInfoLineDisabled,
            infoLineEnabled: false,
        };
    };

    const infoLineData = getInfoLineStatus().translations;
    const infoLineIsEnabled = getInfoLineStatus().infoLineEnabled;

    if (mobileMenu) {
        return (
            <StyledComponent className="info-line-nav-wrapper">
                <a
                    className="info-line-nav"
                    href={infoLineData.buttonProps.href}
                >
                    <div className="info-line-nav-content">
                        <div className={classnames({
                            'info-line-nav-content-phone': true,
                            'dark-color': !isScrolledTop,

                        })}
                        >
                            {infoLineData.buttonProps.phoneLabel}
                        </div>
                        <div className={classnames({
                            'info-line-nav-content-status': true,
                            'active': infoLineIsEnabled,
                        })}
                        >
                            {infoLineData.label}
                        </div>
                    </div>
                </a>
            </StyledComponent>
        );
    }

    return (
        <StyledComponent className="info-line-nav-wrapper">
            <Link
                href={infoLineData.buttonProps.href}
                prefetch={false}
            >
                <a
                    className="info-line-nav"
                    onClick={(e) => {
                        if (!mobileMenu) {
                            e.preventDefault();
                            setShowDropDown(!showDropDown);
                        }
                    }}
                    aria-label="infoline"
                >
                    <div className="info-line-nav-icon">
                        <img
                            src={isScrolledTop ? infoLineData.iconLight : infoLineData.icon}
                            alt="info-line"
                            width="20px"
                            height="20px"
                        />
                    </div>
                    {!isMobile && (
                        <div className="info-line-nav-content">
                            <div className={classnames({
                                'info-line-nav-content-phone': true,
                                'dark-color': !isScrolledTop,

                            })}
                            >
                                {infoLineData.buttonProps.phoneLabel}
                            </div>
                            <div className={classnames({
                                'info-line-nav-content-status': true,
                                'active': infoLineIsEnabled,
                            })}
                            >
                                {infoLineData.label}
                            </div>
                        </div>
                    )}
                </a>
            </Link>
            <div
                className={classnames({
                    'info-line-drop-down': true,
                    'active': showDropDown,
                })}
            >
                <p
                    className={classnames({
                        'status': true,
                        'enabled': infoLineIsEnabled,
                    })}
                >
                    {infoLineData.label}
                </p>
                {infoLineData?.buttonProps?.callLabel && (
                    <Link
                        href={infoLineData.buttonProps.href}
                        prefetch={false}
                    >
                        <a>
                            {infoLineData.buttonProps.callLabel}
                        </a>
                    </Link>
                )}
            </div>
        </StyledComponent>

    );
};

export default InfoLineNavSection;
